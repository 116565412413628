import React, { useState } from 'react'
import TextCardNeighbourhood from '../molecules/TextCardNeighbourhood'
import Image from '~/components/atoms/Image'
import FadeInWhenVisible from '~/components/FadeInWhenVisible'


export default function Neighbourhood({ key, index, postcodeParameter, ...props }) {

	const [viewMore, setViewMore] = useState(12)

	const section = props.neighbourhood
	const section2 = props.neighbourhood.neighbourhoodImages

	return (
		<section id={section.fieldGroupName + index} className="mb-24 md:mb-40 py-40 pb-20 md:pb-4 bg-sand-light relative">
			<div className="container">
				<div className="grid grid-cols-12">
					<div className="col-span-12 md:col-span-12 mb-10 grid md:grid-cols-5 h-full items-center">
						<TextCardNeighbourhood className="text-center col-span-5 flex justify-center " {...section.textCard} />
					</div>
				</div>
				<section id={section.fieldGroupName + index} className="">
					<div className="grid grid-cols-12 gap-8 gap-y-8 items-center">
						{section2.neighbourhoodImages.map((neighbourhoodImages, i) => (
							i < viewMore ?
							<FadeInWhenVisible key={`neighbourhoodImages${i}`} transition={viewMore === 12 && { delay: ((i * .1) + .05) }} className="col-span-12 md:col-span-4 text-center flex justify-center items-center	">
								<Image data={neighbourhoodImages.image} className="w-[85.34px] h-[68.44px] mr-5" objectFit="contain" objectPosition="center" />
								<div className="text-h6 mb-2 text-left w-40" dangerouslySetInnerHTML={{ __html: neighbourhoodImages.heading, }} />
							</FadeInWhenVisible>
							: ''
						))}
					</div>
					<div 
							onClick={() => {
								viewMore === 12 ?
								setViewMore(section2.neighbourhoodImages.length)
								:
								setViewMore(12)
							}}  
							onKeyDown={() => {
								viewMore === 12 ?
								setViewMore(section2.neighbourhoodImages.length)
								:
								setViewMore(12)
							}}   
							className='grid grid-cols-12'
						>
						<p className='group overflow-hidden py-1 text-current uppercase font-heading font-bold tracking-wide transition-colors duration-200 ease-in-out inline-block text-sm relative text-link col-span-12 mx-auto mt-14'>{viewMore === 12 ? 'VIEW MORE' : 'VIEW LESS'}</p>
					</div >
				</section>
			</div>
		</section>
	)
}
