import React from 'react'
import Image from '~/components/atoms/Image'
import FadeInWhenVisible from '~/components/FadeInWhenVisible'

export default function TextCardNeighbourhood({
  className = '',
  innerClassName = '',
  illustration,
  heading,
  headingClassName = '',
  subheading,
  subheadingColor,
  subheadingClassName = '',
  content,
  buttons,
  alignText = 'left',
  image = false,
  differentextAndTitle= false
}) {

  const headingType = heading?.type
  const HeadingTag = headingType && headingType.substr(0, 1) === "h" ? headingType : "h2";

  if(!illustration && !heading.text && !subheading && !content && !buttons)
    return null

  return (
    
    <div className={`space-y-6 ${className} text-${alignText}`}>
      <div className={`space-y-4 ${innerClassName}`}>
        {illustration && (
          <FadeInWhenVisible>
            <Image data={illustration} className={`${image && 'hidden'} h-44 w-44 ${alignText === 'center' && 'mx-auto'}`} objectFit="contain" objectPosition="center" />
          </FadeInWhenVisible>
        )}
        {subheading && (
          <FadeInWhenVisible transition={{ delay: '.05' }}>
            {subheading && <h6 className={`text-h6 ${(subheadingColor === 'Blue') ? 'text-blue' : 'text-navy text-opacity-50'} ${subheadingClassName}`}>{subheading}</h6>}
          </FadeInWhenVisible>
        )}
        {(heading && heading.text && HeadingTag) && (
          <FadeInWhenVisible className='flex justify-center' transition={{ delay: '.1' }}>
            {(heading && heading.text && HeadingTag) && <HeadingTag className={`lg:text-${differentextAndTitle ? 'h4 ': headingType} ${headingClassName} mb-14 font-medium md:font-bold text-4xl w-full md:w-10/12	lg:w-7/12 `}>{heading.text}</HeadingTag>}
          </FadeInWhenVisible>
        )}
        {content && (
          <FadeInWhenVisible className='flex justify-center' transition={{ delay: '.15' }}>
            {content && <div className={`text-body-large prose ${differentextAndTitle && 'text-base'} mb-14` } dangerouslySetInnerHTML={{ __html: content, }} />}
          </FadeInWhenVisible>
        )}
      </div>


    </div>
  )
}
